.BannerWrapper {
  display: flex;
  padding: 9px 0;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--Blue-Lightest-Shade, #e5efff);
  @media (max-width: 968px) {
    display: flex;
    padding: 8px 24px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }
  .bannerBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: -webkit-fill-available;
    max-width: 1350px;
    @media (max-width: 968px) {
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
    .headerlink {
      color: var(--Blue-Dark-Love, #003da6);
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      text-decoration-line: underline;
      @media (max-width: 968px) {
        font-size: 12px;
      }
    }
    .callus {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--Basic-Black, #000);
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 968px) {
        font-size: 12px;
        svg {
          max-width: 16px;
          max-height: 16px;
        }
      }
      span {
        color: var(--Blue-Dark-Love, #003da6);
        text-align: center;

        /* Web/B3 */
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        @media (max-width: 968px) {
          font-size: 14px;
        }
      }
    }
  }
}
