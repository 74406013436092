.Footer {
  display: flex;
  width: -webkit-fill-available;
  padding-top: 64px;
  flex-direction: column;
  align-items: center;
  background: var(--Blue-Lightest-Shade, #e5efff);
  @media (max-width: 960px) {
    padding-top: 24px;
  }
  .footer-wrapper {
    width: 100%;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 960px) {
      gap: 24px;
      align-items: center;
      svg {
        max-width: 90vw;
      }
    }
    .upperbox {
      display: flex;
      padding: 0px 40px;
      align-items: flex-start;
      gap: 35px;
      align-self: stretch;
      @media (max-width: 960px) {
        flex-direction: column;
        padding: 0 24px;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 23px;
        max-width: 30%;
        @media (max-width: 960px) {
          max-width: none;
        }
        svg {
          max-width: 97px;
          max-height: 117px;
        }
        p {
          color: var(--Basic-Dark-Grey, #6f6f6f);
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 177.778% */
          text-align: left;
        }
        .social {
          margin-top: 23px;
          display: flex;
          align-items: flex-start;
          gap: 24px;
          @media (max-width: 960px) {
            margin-top: 0;
          }
          svg {
            max-width: 24px;
            max-height: 24px;
            transition: 300ms;
            &:hover {
              scale: 1.2;
            }
          }
        }
      }
      .right {
        display: flex;
        align-items: flex-start;
        flex: 1 0 0;
        @media (max-width: 960px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, auto);
          grid-column-gap: 24px;
          grid-row-gap: 24px;
          .box:last-child:nth-last-child(odd) {
            grid-column: auto / span 2;
          }
        }
        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          flex: 1 0 0;
          text-align: left;
          .head {
            color: var(--Basic-Black, #000);
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }
          .itms {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            .item {
              color: var(--Basic-Dark-Grey, #6f6f6f);
              font-family: Montserrat;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              display: flex;
              align-items: center;
              align-items: flex-start;
              gap: 8px;
              height: 24px;

              svg {
                width: 15px;
                height: 15px;
                flex-shrink: 0;
                transition: 300ms;
              }
              &:hover {
                color: #003da6;
                svg {
                  scale: 1.1;
                }
              }
            }
          }
        }
      }
    }
    .lower {
      display: flex;
      padding-bottom: 24px;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      align-self: stretch;
      @media (max-width: 960px) {
        flex-direction: column;
        gap: 10px;
      }
      p {
        color: var(--Basic-Black, #000);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
}
