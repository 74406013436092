.Navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--Basic-White, #fff);
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: -webkit-fill-available;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      width: -webkit-fill-available;
      max-width: 1350px;
      padding: 12px 0;
      .links-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        a {
          color: var(--Basic-Black, #000);
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 27px */
          padding: 24px 0;
          &:hover {
            color: #003da6;
          }
        }
        .drop-down-link {
          cursor: pointer;
          color: var(--Basic-Black, #000);
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 27px */
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          position: relative;
          &:hover {
            color: #003da6;
          }
          .drop-menu {
            position: absolute;
            top: calc(100% + 42px);
            left: -20px;
            width: max-content;
            display: flex;
            padding: 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            background: var(--Basic-White, #fff);
            z-index: 6;
            .menu-box {
              display: flex;
              padding: 12px;
              align-items: center;
              gap: 16px;
              align-self: stretch;
              border-radius: 8px;
              background: #fff;
              &:hover {
                background: #5a91f2;
                color: #fff;
                svg {
                  scale: 1.2;
                }
              }
              .text-div {
                display: flex;
                align-items: center;
                gap: 16px;
                text-align: left;
                padding: 7px 0;
              }
              svg {
                transition: 300ms;
              }
            }
          }
        }
      }
      .headerButton {
        display: flex;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: none;
        background: var(--Blue-Dark-Love, #003da6);
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 21.6px */
        &:hover {
          background: #042867;
        }
      }
    }
  }
  .mobileNav {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: var(--Basic-White, #fff);
    width: 100%;
    position: relative;
    .logo {
      svg {
        max-width: 110px;
        max-height: 33px;
      }
    }
    .menu-icon {
      width: 24px;
      height: 24px;
    }
    .dropdown-links-wrapper {
      z-index: 10;
      position: absolute;
      top: 100%;
      left: 0;
      width: -webkit-fill-available;
      display: flex;
      padding: 12px 20px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background-color: #fff;
      a {
        color: #393939;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        display: flex;
        padding: 24px 12px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
      }
      .supmenuparent {
        color: #393939;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        display: flex;
        padding: 24px 12px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        justify-content: space-between;
      }
      .drop-down-link {
        width: -webkit-fill-available;
      }
      .MobDrop {
        display: flex;
        padding: 12px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: #f9f9f9;
        width: -webkit-fill-available;
        .text-div {
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          text-align: left;
          svg {
            max-width: 22px;
            max-height: 22px;
          }
          img {
            max-width: 22px;
            max-height: 22px;
          }
        }
      }
    }
  }
}
