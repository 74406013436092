h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
}
.App {
  text-align: center;
  scroll-behavior: smooth;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap");

a,
button,
.button {
  transition: 300ms;
  cursor: pointer;
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
