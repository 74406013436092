.TestimonialsSwiper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 80px;
  @media (max-width: 1200px) {
    padding-left: 24px;
  }
  .testimonails {
    width: 100%;
    .box {
      display: flex;
      padding: 34px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;
      justify-content: space-between;
      gap: 21px;
      text-align: left;
      // min-height: 350px;
      // max-width: 450px;
      @media (max-width: 960px) {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
      }
      .textbox {
        display: flex;
        flex-direction: column;
        gap: 21px;
        .title {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 36px */
          text-transform: capitalize;
          @media (max-width: 1200px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            text-transform: capitalize;
          }
        }
        .note {
          overflow: hidden;
          color: var(--Basic-Black, #000);
          text-overflow: ellipsis;
          white-space: break-spaces;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          @media (max-width: 1200px) {
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
        }
      }

      .author {
        display: flex;
        align-items: center;
        gap: 16px;
        // flex: 1 0 0;
        @media (max-width: 960px) {
          align-self: flex-start;
        }
        .name {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          flex: 1 0 0;
          flex-direction: column-reverse;
          @media (max-width: 1200px) {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
            flex-direction: column-reverse;
          }
          .stars {
            display: flex;
            align-items: flex-start;
            gap: 5.926px;
            @media (max-width: 960px) {
              label {
                span {
                  svg {
                    max-width: 16px;
                    max-height: 16px;
                  }
                }
              }
            }
            .css-iljtu2-MuiRating-root {
              width: 20px;
              height: 20px;
              color: #219d50;
            }
          }
          p {
            color: var(--Basic-Black, #000);
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 36px */
          }
        }
        img {
          max-width: 100px;
          max-height: 100px;
          @media (max-width: 960px) {
            max-width: 55px;
            max-height: 55px;
          }
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin-top: 50px;
      @media (max-width: 1200px) {
        gap: 10px;
        margin-top: 24px;
      }
      button {
        width: 57px;
        height: 57px;
        border-radius: 47px;
        border: 2.5px solid var(--Blue-Dark-Love, #003da6);
        background: var(--Basic-White, #fff);
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1200px) {
          max-width: 44px;
          max-height: 44px;
        }
      }
      button:hover {
        background-color: #003da613;
      }
    }
  }
  .swiper {
    width: 100%;
    // max-width: 1300px;
    overflow: visible;
    @media (max-width: 960px) {
      max-width: 100vw;
    }
  }
  .swiper-wrapper {
    margin-top: 30px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    height: initial;
  }
  .swiper-button-next {
    display: none;
  }
  .swiper-button-prev {
    display: none;
  }
}
