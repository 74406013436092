.HomeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow: hidden;
  .SectionOne {
    display: flex;
    padding: 80px;
    align-items: center;
    align-self: stretch;
    background-color: var(--Blue-Dark-Love, #003da6);
    background-image: url("../../assets/Mask-Group.webp");
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      align-items: center;
      width: -webkit-fill-available;
      min-height: 630px;
      @media (max-width: 960px) {
        flex-direction: column;
        max-width: calc(100vw - 48px);
        gap: 40px;
        min-height: 80vh;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        position: relative;
        text-align: left;
        width: 53%;
        @media (max-width: 960px) {
          width: -webkit-fill-available;
        }
        .floatingstar {
          position: absolute;
          top: -10px;
          right: 0;
          @media (max-width: 960px) {
            top: 25%;
            max-width: 40px;
          }
        }
        .note {
          display: inline-flex;
          padding: 9px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 18px;
          background: #7191c9;
          color: #fff;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          letter-spacing: 2.56px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-weight: 600;
            font-size: 12px;
            padding: 10px 14px;
            align-self: center;
          }
        }
        .heading {
          color: #fff;
          font-family: Montserrat;
          font-size: 58px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          @media (max-width: 960px) {
            font-size: 40px;
          }
          .boldwhite {
            color: #fff;
            font-family: Montserrat;
            font-size: 62px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 74.4px */
            @media (max-width: 960px) {
              font-size: 42px;
            }
          }
          .boldBlue {
            color: var(--Blue-Lighter-Shade, #7191c9);
            font-family: Montserrat;
            font-size: 62px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            @media (max-width: 960px) {
              font-size: 42px;
            }
          }
          .lightblue {
            color: var(--Blue-Lighter-Shade, #7191c9);
            font-family: Montserrat;
            font-size: 58px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            @media (max-width: 960px) {
              font-size: 42px;
            }
          }
        }
        .desc {
          color: rgba(255, 255, 255, 0.5);
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 27px */
          max-width: 75%;
          @media (max-width: 960px) {
            max-width: -webkit-fill-available;
          }
        }
        .buttons {
          margin-top: 12px;
          display: flex;
          align-items: flex-start;
          gap: 24px;
          .red {
            display: flex;
            padding: 16px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: var(--Red-Blood-Love, #c10030);
            color: #fff;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            border: none;
            &:hover {
              background-color: white;
              color: #c10030;
            }
          }
          .empty {
            display: flex;
            padding: 16px 32px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid var(--Blue-Lightest-Shade, #e5efff);
            color: var(--Blue-Lightest-Shade, #e5efff);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
            background-color: transparent;
            &:hover {
              background-color: #fff;
              color: #c10030;
            }
          }
        }
      }
      .right {
        max-width: 637px;
        width: -webkit-fill-available;
        // img {
        //   max-width: 637px;
        // }
        @media (max-width: 960px) {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .buttons {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .red {
              display: flex;
              padding: 16px 32px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 8px;
              background: var(--Red-Blood-Love, #c10030);
              color: #fff;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              border: none;
              &:hover {
                background-color: white;
                color: #c10030;
              }
            }
            .empty {
              display: flex;
              padding: 16px 32px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid var(--Blue-Lightest-Shade, #e5efff);
              color: var(--Blue-Lightest-Shade, #e5efff);
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 21.6px */
              background-color: transparent;
              &:hover {
                background-color: #fff;
                color: #c10030;
              }
            }
          }
        }
      }
    }
  }
  .SectionTwo {
    display: flex;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      @media (max-width: 960px) {
        max-width: calc(100vw - 48px);
        width: -webkit-fill-available;
      }
      .upper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        text-align: center;
        @media (max-width: 960px) {
          width: -webkit-fill-available;
        }
        .note {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          letter-spacing: 2.56px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 14.4px */
            letter-spacing: 1.92px;
          }
        }
        .heading {
          color: var(--Basic-Black, #000);
          font-family: Montserrat;
          font-size: 52px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%; /* 62.4px */
          @media (max-width: 960px) {
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 33.6px */
            br {
              display: none;
            }
          }
        }
        .desc {
          color: var(--Basic-Black, #000);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          @media (max-width: 960px) {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            br {
              display: none;
            }
          }
        }
      }
      .boxes {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;

        img {
          transition: 300ms;
          max-width: 24%;
          &:hover {
            scale: 1.1;
          }
        }
        @media (max-width: 960px) {
          display: flex;
          // width: 342px;
          width: -webkit-fill-available;
          // height: 338px;
          align-items: center;
          align-content: center;
          gap: 8px;
          flex-wrap: wrap;
          justify-content: center;
          img {
            display: flex;
            max-width: 48%;
            align-items: flex-end;
            flex-shrink: 0;
          }
        }
      }
      .ctabutton {
        display: flex;
        padding: 16px 29px;
        align-items: center;
        gap: 20px;
        border-radius: 6px;
        border: 2.5px solid var(--Blue-Dark-Love, #003da6);
        background-color: transparent;
        color: var(--Blue-Dark-Love, #003da6);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 21.6px */
        @media (max-width: 960px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 16.8px */
        }
        svg {
          transition: 300ms;
        }
        &:hover {
          svg {
            transform: translateX(10px);
          }
        }
      }
    }
  }
  .SectionThree {
    display: flex;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Blue-Lightest-Shade, #e5efff);
    position: relative;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      z-index: 2;
      @media (max-width: 960px) {
        max-width: calc(100vw - 48px);
        gap: 40px;
        width: -webkit-fill-available;
      }
      .upper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        text-align: left;
        @media (max-width: 960px) {
          align-items: center;
        }
        .note {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          letter-spacing: 2.56px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 12px;
            font-weight: 700;
            line-height: 120%; /* 14.4px */
            letter-spacing: 1px;
            text-align: center;
          }
        }
        .heading {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 52px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%; /* 62.4px */
          @media (max-width: 960px) {
            font-size: 28px;
            br {
              display: none;
            }
          }
        }
      }
      .boxes {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        @media (max-width: 960px) {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          overflow: scroll;
        }
        .whitebox {
          background: var(--Basic-White, #fff);
          display: flex;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          flex: 1 0 0;
          text-align: left;
          gap: 24px;
          @media (max-width: 960px) {
            display: flex;
            // width: clamp(250px, 300px, 400px);
            max-width: 300px;
            padding: 24px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
            flex: none;
          }
          &:hover {
            svg {
              scale: 1.3;
            }
          }
          .title {
            color: var(--Blue-Dark-Love, #003da6);
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 36px */
            text-transform: capitalize;
            @media (max-width: 960px) {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 24px */
              text-transform: capitalize;
            }
          }
          .desc {
            color: var(--Basic-Black, #000);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 27px */
            @media (max-width: 960px) {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
            }
          }
          .imagewrap {
            margin-top: 76px;
            display: flex;
            width: 100px;
            height: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: var(--Blue-Lightest-Shade, #e5efff);
            svg {
              transition: 300ms;
              max-width: 40px;
              max-height: 40px;
              flex-shrink: 0;
            }
            @media (max-width: 960px) {
              width: 64px;
              height: 64px;
              svg {
                max-width: 25.6px;
                max-height: 25.6px;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
    .BGcircles {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      @media (max-width: 960px) {
        max-width: 70%;
      }
    }
  }
  .SectionFour {
    display: flex;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Blue-Dark-Love, #003da6);
    background-image: url("../../assets/Mask\ Group.png");
    background-position: right top;
    background-repeat: no-repeat;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 62px;
      @media (max-width: 960px) {
        flex-direction: column;
        max-width: calc(100vw - 48px);
        gap: 40px;
        width: -webkit-fill-available;
      }
      .upper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 60px;
        position: relative;
        @media (max-width: 960px) {
          flex-direction: column;
          gap: 40px;
          max-width: 100%;
        }
        .left {
          // max-width: 70%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          align-self: stretch;
          text-align: left;
          @media (max-width: 960px) {
            align-items: center;
          }
          .note {
            color: var(--Basic-White, #fff);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
            letter-spacing: 2.56px;
            text-transform: uppercase;
            @media (max-width: 960px) {
              font-size: 12px;
              font-weight: 700;
              line-height: 120%; /* 14.4px */
              letter-spacing: 1px;
              text-align: center;
            }
          }
          .heading {
            color: var(--Basic-White, #fff);
            font-family: Montserrat;
            font-size: 52px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 62.4px */
            @media (max-width: 960px) {
              font-size: 28px;
              br {
                display: none;
              }
            }
          }
          .desc {
            margin-top: 26px;
            color: var(--Basic-Light-Grey, #ededed);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 27px */
          }
        }
        .right {
          max-width: 489px;
          @media (max-width: 960px) {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .desc {
              color: var(--Basic-Light-Grey, #ededed);
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
              text-align: left;
            }
          }
        }
        .bg {
          position: absolute;
          bottom: 0;
          left: 35%;
          @media (max-width: 960px) {
            display: none;
          }
        }
      }
      .boxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        @media (max-width: 960px) {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          overflow: scroll;
          align-self: stretch;
        }
        .box {
          background: #174faf;
          display: flex;
          max-width: 660px;
          max-height: 323px;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 36px;
          flex-shrink: 0;
          @media (max-width: 960px) {
            display: flex;
            max-width: 300px;
            padding: 24px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            flex-shrink: 0;
            align-self: stretch;
            // flex: none;
            svg,
            img {
              max-width: 32px;
              max-height: 32px;
            }
          }
          .textbox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            text-align: left;
            .title {
              color: var(--Basic-White, #fff);
              font-family: Montserrat;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 36px */
              @media (max-width: 960px) {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
                text-transform: capitalize;
              }
            }
            .desc {
              color: var(--Basic-Light-Grey, #ededed);
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              @media (max-width: 960px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }
          }
        }
      }
      .cta {
        display: flex;
        padding: 32px 0px;
        align-items: center;
        justify-content: space-between;
        width: -webkit-fill-available;
        @media (max-width: 960px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 40px;
          align-self: stretch;
        }
        .left {
          display: flex;
          align-items: center;
          gap: 38px;
          flex: 1 0 0;
          @media (max-width: 960px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 38px;
            align-self: stretch;
          }
          p {
            color: var(--Blue-Lightest-Shade, #e5efff);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 27px */
            text-align: left;
            @media (max-width: 960px) {
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 27px */
            }
          }
        }
        .right {
          display: flex;
          padding: 16px 29px;
          align-items: center;
          gap: 20px;
          border-radius: 6px;
          background: var(--Basic-White, #fff);
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 21.6px */
          border: none;
          @media (max-width: 960px) {
            align-self: stretch;
            justify-content: space-between;
          }
          svg {
            transition: 300ms;
          }
          &:hover {
            svg {
              transform: translateX(10px);
            }
          }
        }
      }
    }
  }
  .SectionFive {
    display: flex;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      @media (max-width: 960px) {
        max-width: calc(100vw - 48px);
        gap: 40px;
        width: -webkit-fill-available;
      }
      .upper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        text-align: center;
        @media (max-width: 960px) {
          text-align: left;
          align-items: flex-start;
        }
        .note {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          letter-spacing: 2.56px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 12px;
            line-height: 120%; /* 14.4px */
            letter-spacing: 1px;
            text-align: left;
          }
        }
        .heading {
          color: var(--Basic-Black, #000);
          font-family: Montserrat;
          font-size: 52px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%; /* 62.4px */
          @media (max-width: 960px) {
            font-size: 28px;
            br {
              display: none;
            }
          }
        }
      }
      .boxes {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        @media (max-width: 960px) {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          overflow: scroll;
        }
        .box {
          background: var(--Blue-Lightest-Shade, #e5efff);
          display: flex;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          text-align: left;
          gap: 36px;
          align-self: stretch;
          @media (max-width: 960px) {
            display: flex;
            // width: clamp(250px, 300px, 400px);
            max-width: 300px;
            padding: 24px;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
            flex: none;
          }
          &:hover {
            svg {
              scale: 1.3;
            }
          }
          .desc {
            color: var(--Basic-Black, #000);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            @media (max-width: 960px) {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
            }
          }
          .imagewrap {
            display: flex;
            width: 100px;
            height: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: var(--Basic-White, #fff);
            svg {
              transition: 300ms;
              max-width: 40px;
              max-height: 40px;
              flex-shrink: 0;
            }
            @media (max-width: 960px) {
              width: 64px;
              height: 64px;
              svg {
                max-width: 25.6px;
                max-height: 25.6px;
                flex-shrink: 0;
              }
            }
          }
        }
      }
      .ctabutton {
        display: flex;
        padding: 16px 29px;
        align-items: center;
        gap: 20px;
        border-radius: 6px;
        border: 2.5px solid var(--Blue-Dark-Love, #003da6);
        background-color: transparent;
        color: var(--Blue-Dark-Love, #003da6);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 21.6px */
        svg {
          transition: 300ms;
        }
        &:hover {
          svg {
            transform: translateX(10px);
          }
        }
        @media (max-width: 960px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 16.8px */
        }
      }
    }
  }
  .SectionSix {
    display: flex;
    padding: 100px 80px;
    align-items: center;
    background: #f7f7f7;
    align-self: stretch;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      align-items: center;
      gap: 120px;
      @media (max-width: 960px) {
        max-width: calc(100vw - 48px);
        gap: 40px;
        width: -webkit-fill-available;
        flex-direction: column-reverse;
      }
      .left {
        max-width: 580px;
        @media (max-width: 960px) {
          max-width: 100%;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;
        flex: 1 0 0;
        .upper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          align-self: stretch;
          text-align: left;
          .note {
            color: var(--Blue-Dark-Love, #003da6);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
            letter-spacing: 2.56px;
            text-transform: uppercase;
            @media (max-width: 960px) {
              font-size: 12px;
              line-height: 120%; /* 14.4px */
              letter-spacing: 1px;
              text-align: left;
            }
          }
          .heading {
            color: var(--Basic-Black, #000);
            font-family: Montserrat;
            font-size: 52px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 62.4px */
            @media (max-width: 960px) {
              font-size: 28px;
              br {
                display: none;
              }
            }
          }
        }
        .lower {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 80px;
          align-self: stretch;
          position: relative;
          .line {
            position: absolute;
            z-index: 1;
            left: 40px;
            width: 1px;
            height: calc(100% - 50px);
            background: linear-gradient(
              90deg,
              rgba(0, 61, 166, 0) 0%,
              #003da6 48.1%,
              rgba(0, 61, 166, 0) 88.8%
            );
            @media (max-width: 960px) {
              left: 32px;
              max-height: 80%;
              top: 10%;
            }
          }
          .box {
            display: flex;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            z-index: 2;
            .nubmer {
              display: flex;
              min-width: 80px;
              height: 80px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 50px;
              background: var(--Blue-Lighter-Shade, #7191c9);
              color: var(--Blue-Lightest-Shade, #e5efff);
              font-family: Montserrat;
              font-size: 32px;
              font-style: normal;
              font-weight: 900;
              line-height: 32px; /* 100% */
              transition: 300ms;
              cursor: pointer;
              &:hover {
                scale: 1.2;
              }
              @media (max-width: 960px) {
                display: flex;
                min-width: 64px;
                height: 64px;
                font-size: 28px;
                font-weight: 700;
                border-radius: 80px;
              }
            }
            .desc {
              color: var(--Basic-Black, #000);
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              text-align: left;
              @media (max-width: 960px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }
          }
        }
      }
    }
  }
  .SectionSeven {
    display: flex;
    padding: 100px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Blue-Lightest-Shade, #e5efff);
    @media (max-width: 960px) {
      padding: 40px 0;
    }
    .wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 56px;
      @media (max-width: 960px) {
        gap: 20px;
        width: -webkit-fill-available;
        flex-direction: column;
      }
      .upper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        text-align: center;
        padding: 0 80px;
        max-width: 1350px;
        @media (max-width: 960px) {
          max-width: calc(100vw - 48px);
          width: -webkit-fill-available;
          align-items: flex-start;
          padding: 0;
          text-align: left;
        }
        .note {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          letter-spacing: 2.56px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 12px;
            line-height: 120%; /* 14.4px */
            letter-spacing: 1px;
            text-align: left;
          }
        }
        .heading {
          color: var(--Basic-Black, #000);
          font-family: Montserrat;
          font-size: 52px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%; /* 62.4px */
          @media (max-width: 960px) {
            font-size: 28px;
          }
        }
      }
    }
  }
  .SectionEight {
    display: flex;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media (max-width: 960px) {
      padding: 40px 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      width: -webkit-fill-available;
      @media (max-width: 960px) {
        max-width: calc(100vw - 48px);
        gap: 40px;
        width: -webkit-fill-available;
      }
      .upper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        text-align: center;
        @media (max-width: 960px) {
          text-align: left;
          align-items: flex-start;
        }
        .note {
          color: var(--Blue-Dark-Love, #003da6);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          letter-spacing: 2.56px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 12px;
            line-height: 120%; /* 14.4px */
            letter-spacing: 1px;
            text-align: left;
          }
        }
        .heading {
          color: var(--Basic-Black, #000);
          font-family: Montserrat;
          font-size: 52px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%; /* 62.4px */
          @media (max-width: 960px) {
            font-size: 28px;
            br {
              display: none;
            }
          }
        }
      }
      .lower {
        display: flex;
        padding: 24px;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
        background: var(--Blue-Lightest-Shade, #e5efff);
        @media (max-width: 960px) {
          display: flex;
          padding: 12px;
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        }
        .left {
          border-radius: 10px;
          @media (max-width: 960px) {
            max-width: 100%;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          width: -webkit-fill-available;
          .whitebox {
            display: flex;
            padding: 22px 27px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 40px;
            align-self: stretch;
            background: var(--Basic-White, #fff);
            width: -webkit-fill-available;
            svg {
              transition: 300ms;
            }
            &:hover {
              svg {
                scale: 1.2;
              }
            }
            @media (max-width: 960px) {
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              svg {
                max-width: 28px;
                max-height: 28px;
              }
            }
            .text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              align-self: stretch;
              text-align: left;
              .title {
                color: var(--Blue-Dark-Love, #003da6);

                /* Web/B1-Bold */
                font-family: Montserrat;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                @media (max-width: 960px) {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%; /* 24px */
                  text-transform: capitalize;
                }
              }
              .desc {
                color: var(--Basic-Black, #000);

                /* Web/B2 */
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 27px */
                @media (max-width: 960px) {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                }
              }
            }
          }
          .ctaButton {
            display: flex;
            padding: 16px 29px;
            align-items: center;
            gap: 20px;
            border-radius: 6px;
            border: 2.5px solid var(--Blue-Dark-Love, #003da6);
            background: var(--Basic-White, #fff);
            color: var(--Blue-Dark-Love, #003da6);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
            align-self: unset;
            svg {
              transition: 300ms;
            }
            &:hover {
              svg {
                transform: translateX(10px);
              }
            }
          }
        }
        .right.mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 16px;
          width: -webkit-fill-available;
          .whitebox {
            display: flex;
            padding: 22px 27px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 40px;
            align-self: stretch;
            background: var(--Basic-White, #fff);
            width: -webkit-fill-available;
            svg {
              transition: 300ms;
            }
            &:hover {
              svg {
                scale: 1.2;
              }
            }
            @media (max-width: 960px) {
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              svg {
                max-width: 28px;
                max-height: 28px;
              }
            }
            .text {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              align-self: stretch;
              .title {
                color: var(--Blue-Dark-Love, #003da6);

                /* Web/B1-Bold */
                font-family: Montserrat;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                @media (max-width: 960px) {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%; /* 24px */
                  text-transform: capitalize;
                }
              }
            }
            .desc {
              color: var(--Basic-Black, #000);
              /* Web/B2 */
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 27px */
              @media (max-width: 960px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-align: left;
              }
            }
          }
          .ctaButton {
            display: flex;
            padding: 16px 29px;
            align-items: center;
            gap: 20px;
            border-radius: 6px;
            border: 2.5px solid var(--Blue-Dark-Love, #003da6);
            background: var(--Basic-White, #fff);
            color: var(--Blue-Dark-Love, #003da6);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
            align-self: unset;
            svg {
              transition: 300ms;
            }
            &:hover {
              svg {
                transform: translateX(10px);
              }
            }
          }
        }
      }
      .ctaButton {
        display: flex;
        padding: 16px 29px;
        align-items: center;
        gap: 20px;
        border-radius: 6px;
        border: 2.5px solid var(--Blue-Dark-Love, #003da6);
        background: var(--Basic-White, #fff);
        color: var(--Blue-Dark-Love, #003da6);
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 16.8px */
        align-self: stretch;
        justify-content: space-between;
        svg {
          transition: 300ms;
        }
        &:hover {
          svg {
            transform: translateX(10px);
          }
        }
      }
    }
  }
  .SectionNine {
    display: flex;
    padding: 32px 80px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    background-color: var(--Blue-Dark-Love, #003da6);
    background-image: url("../../assets/Mask\ Group.png");
    background-position: right;
    background-repeat: no-repeat;
    @media (max-width: 960px) {
      padding: 24px;
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      width: -webkit-fill-available;
      @media (max-width: 960px) {
        width: -webkit-fill-available;
        flex-direction: column;
        gap: 24px;
      }
      .card {
        display: flex;
        padding: 0px 24px;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        @media (max-width: 960px) {
          padding: 0;
          gap: 16px;
        }
        svg {
          transition: 300ms;
        }
        &:hover {
          svg {
            scale: 1.2;
          }
        }
        .left {
          display: flex;
          width: 80px;
          height: 80px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 80px;
          background: rgba(113, 145, 201, 0.2);
          @media (max-width: 960px) {
            width: 64px;
            height: 64px;
            gap: 6.4px;
            svg {
              max-width: 35.2px;
              max-height: 35.2px;
              flex-shrink: 0;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;
          text-align: left;
          .title {
            color: var(--Basic-White, #fff);
            width: max-content;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 36px */
            @media (max-width: 960px) {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 24px */
              text-transform: capitalize;
            }
          }
          .desc {
            color: var(--Basic-White, #fff);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            @media (max-width: 960px) {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }
          }
        }
      }
    }
  }
}
